import React, {Suspense} from 'react'
import ReservationForm from '../../modules/apps/components/ReservationForm'
import ReservationList from '../../modules/apps/components/ReservationList'
import Promo from '../../modules/apps/components/Promo'
import Header from '../../modules/apps/components/Header'
import Footer from '../../modules/apps/components/Footer'
import WhatsappButton from '../../modules/apps/components/utils/WhatsappButton'

// Lazy load desde Services hacia abajo
const Services = React.lazy(() => import('../../modules/apps/components/Services'))
const JoinComponent = React.lazy(() => import('../../modules/apps/components/JoinComponent'))
const Quality = React.lazy(() => import('../../modules/apps/components/Quality'))
const Offices = React.lazy(() => import('../../modules/apps/components/Offices'))
const QuestionsAnswers = React.lazy(() => import('../../modules/apps/components/QuestionsAnswers'))
const Centers = React.lazy(() => import('../../modules/apps/components/Centers'))
const ContactUs = React.lazy(() => import('../../modules/apps/components/ContactUs'))

const PrincipalPage = () => {
  return (
    <>
      <Header />
      <style>
        {`
  .custom-container {
    position: absolute;
    top: 30%;
    left: 1rem; /* left-4 */
    right: 1rem; /* right-4 */
  }

  @media (min-width: 768px) {
    .custom-container {
      top: 30%; /* md:top-[30%] */
    }
  }

  @media (min-width: 1024px) {
    .custom-container {
      top: 50%; /* lg:top-[50%] */
      left: 4rem; /* lg:left-16 */
      transform: translateY(-50%); /* lg:-translate-y-1/2 */
      width: 40%; /* lg:w-[40%] */
    }
  }

  @media (min-width: 1280px) {
    .custom-container {
      top: 50%; /* xl:top-[50%] */
      left: 6rem; /* xl:left-24 */
      width: 40%; /* xl:w-[40%] */
    }
  }

  .custom-title {
    font-weight: bold; /* font-bold */
    font-size: 1.125rem; /* text-lg */
    color: #dc2626; /* text-red-600 */
    width: 40%; /* w-[40%] */
  }

  @media (min-width: 1024px) {
    .custom-title {
      font-size: 2.25rem; /* lg:text-4xl */
      width: 70%; /* lg:w-[70%] */
    }
  }

  @media (min-width: 1280px) {
    .custom-title {
      font-size: 3rem; /* xl:text-5xl */
      width: 80%; /* xl:w-[80%] */
    }
  }

  .custom-subtitle {
    font-size: 0.75rem; /* text-xs */
    color: #ffffff; /* text-white */
  }

  @media (min-width: 768px) {
    .custom-subtitle {
      font-size: 1rem; /* md:text-xl */
    }
  }

  @media (min-width: 1024px) {
    .custom-subtitle {
      font-size: 1.5rem; /* lg:text-2xl */
    }
  }

  @media (min-width: 1280px) {
    .custom-subtitle {
      font-size: 2.25rem; /* xl:text-4xl */
    }
  }
`}
      </style>
      <div style={{paddingTop: '50px', backgroundColor: 'white'}}>
        <div className='h-100 position-relative'>
          <picture>
            <source
              srcSet='images/banner/PiuraRentaCarb5movil.webp'
              media='(max-width: 600px)'
              width={600}
            />
            <source
              srcSet='images/banner/PiuraRentaCarb5.webp'
              media='(min-width: 601px)'
              width={1200}
            />
            <img
              src='images/banner/PiuraRentaCarb5.webp'
              className='w-100 object-cover'
              alt='banner-image'
              width='1200'
              height='600'
            />
          </picture>
          <>
            <div className='custom-container'>
              <h1 className='custom-title'>ALQUILA CON NOSOTROS</h1>
              <div className='custom-subtitle'>LA MEJOR FLOTA DE AUTOS</div>
            </div>
          </>
        </div>
      </div>

      <ReservationForm />
      <ReservationList />

      {/* Suspense envuelve los componentes diferidos */}
      <Suspense fallback={<div>Cargando sección de servicios...</div>}>
        <Promo />
        <Services />
        <Quality />
        <JoinComponent />
        <Offices />
        <QuestionsAnswers />
        <ContactUs />
        <Centers />
      </Suspense>
      <WhatsappButton />
      <Footer />
    </>
  )
}

export default PrincipalPage
