import { Handshake, UserRound, UsersRound } from "lucide-react";
import CardInfo from "./utils/CardInfo";
import { useIntl } from "react-intl";

const AboutS2 = () => {
  const intl = useIntl();

  return (
    <>
    <style>
{`
  .about-s2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3.5rem 0; /* py-14 */
    background-color: white; /* bg-white */
    width: 100%; /* w-full */
  }

  .about-s2-content {
    display: flex;
    flex-direction: column; /* flex-col */
    justify-content: center;
    align-items: center;
    gap: 1.25rem; /* gap-5 */
    width: 90vw; /* w-[90vw] */
  }

  @media (min-width: 768px) {
    .about-s2-content {
      width: 75vw; /* md:w-[75vw] */
    }
  }

  @media (min-width: 1024px) {
    .about-s2-content {
      flex-direction: row; /* lg:flex-row */
    }
  }
`}
</style>

<div className="about-s2-container">
  <div className="about-s2-content">
    <CardInfo
      title={intl.formatMessage({ id: "ABOUT-US.VISION" })}
      description={
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "ABOUT-US.VISION-TEXT" }),
            }}
          />
        </>
      }
      icon={<UserRound size={90} strokeWidth={1.85} color="#f20817" />}
    />
    <CardInfo
      title={intl.formatMessage({ id: "ABOUT-US.MISSION" })}
      description={
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "ABOUT-US.MISSION-TEXT" }),
            }}
          />
        </>
      }
      icon={<UsersRound size={90} strokeWidth={1.85} color="#f20817" />}
    />
    <CardInfo
      title={intl.formatMessage({ id: "ABOUT-US.VALUES" })}
      description={
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "ABOUT-US.VALUES-TEXT" }),
            }}
          />
        </>
      }
      icon={<Handshake size={90} strokeWidth={1.85} color="#f20817" />}
    />
  </div>
</div>

    </>
  );
};

export default AboutS2;
