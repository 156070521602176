const WhatsappButton = () => {
  return (
    <>
      <style>
        {`
          .whatsapp-button {
            position: fixed;
            bottom: 1.5rem; /* 24px */
            left: 1.5rem; /* 24px */
            cursor: pointer;
          }
          .whatsapp-button img {
            width: 4rem; /* 64px */
            height: 4rem; /* 64px */
            transition: filter 0.3s ease;
          }
          .whatsapp-button img:hover {
            filter: contrast(200%);
          }
        `}
      </style>

      <a href="https://wa.link/3hlnf6" target="_blank" className="whatsapp-button">
        <img
          src="images/whatsapp.webp"
          alt="whatsapp"
        />
      </a>
    </>
  );
};

export default WhatsappButton;
