import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import PrincipalPage from '../pages/appPages/PrincipalPage'
import AboutPage from '../pages/appPages/AboutPage'
import NotFoundPage from '../pages/appPages/NotFoundPage'
import fetchStore from '../stores/fetchStore'
import {useAuthStore} from '../stores/authStore'
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import { Overview } from '../modules/accounts/components/Overview'

const PrivateRoutes = () => {
  const fetchCars = fetchStore((state) => state.fetchCars)
  const login = useAuthStore((state) => state.login)
  const token = useAuthStore((state) => state.token)

  const handleLogin = async () => {
    try {
      await login()
    } catch (error) {
      console.error('Error logging in:', error)
    }
  }

  useEffect(() => {
    handleLogin()
  }, [])

  useEffect(() => {
    if (token) fetchCars()
  }, [token])

  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: 'Account',
      path: '/crafted/account/overview',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      {/* <Route element={<MasterLayout />}> */}
      {/* Redirect to Dashboard after success login/registartion */}
      <Route path='auth/*' element={<Navigate to='/' />} />
      {/* Pages */}
      <Route path='/' element={<PrincipalPage />} />
      <Route path='/nosotros' element={<AboutPage />} />
      <Route path='/account-overview' element={<>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
              <Overview />
            </>} />
      <Route path='/404' element={<NotFoundPage />} />
      <Route path='/alquiler-de-autos-y-camionetas-piura-y-talara' element={<Navigate to='/' />} />
      <Route path='*' element={<NotFoundPage />} />

      {/* Page Not Found */}
      <Route path='*' element={<Navigate to='/error/404' />} />
      {/* </Route> */}
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
