import {useEffect, useState} from 'react'
import ReservationModal from './utils/ReservationModal'
import {CalendarDays, Info, MapPin, X} from 'lucide-react'
import {useForm} from 'react-hook-form'
import Spinner from './utils/Spinner'
import Swal from 'sweetalert2'
import {Car} from '../../../../requests/cars/interfaces'
import {Center} from '../../../../requests/centers/interfaces'
import fetchStore from '../../../stores/fetchStore'
import {useAuthStore} from '../../../stores/authStore'
import {confirmReserva, createReserva} from '../../../../requests/reservation/api'
import {getCenters} from '../../../../requests/centers/api'
import {useIntl} from 'react-intl'

type FormData = {
  vehicle: string
  lentrega: string
  fentrega: string
  hentrega: string
  ldevolver: string
  fdevolver: string
  hdevolver: string
}

type FormData1 = {
  nombres: string
  apellidoMaterno: string
  apellidoPaterno: string
  celular: string
  edad: string
  email: string
  consentimiento: boolean
}
const ReservationForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [autos, setAutos] = useState<Car[]>([])
  const [centros, setCentros] = useState<Center[]>([])
  const [vehicleText, setVehicleText] = useState<string>('')
  const [lntregaText, setLentregaText] = useState<string>('')
  const [ldevolverText, setLdevolverText] = useState<string>('')
  const [loadingReserva, setLoadingReserva] = useState(false)
  const options = []

  const intl = useIntl()
  const carsData = fetchStore((state) => state.carsData)
  const token = useAuthStore((state) => state.token)

  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      fdevolver: new Date().toISOString().split('T')[0],
      fentrega: new Date().toISOString().split('T')[0],
    },
  })

  const {
    register: registerForm1,
    handleSubmit: handleSubmitForm1,
    formState: {errors: errorsForm1},
    reset: resetForm1,
  } = useForm<FormData1>()

  for (let i = 18; i <= 81; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    )
  }

  const compareTimes = (time1: string, time2: string) => {
    const timeStringToMinutes = (timeString: string) => {
      const [time, modifier] = timeString.split(' ')
      let [hours, minutes] = time.split(':').map(Number)

      if (hours === 12) {
        hours = 0
      }

      if (modifier === 'PM') {
        hours += 12
      }

      return hours * 60 + minutes
    }

    const minutes1 = timeStringToMinutes(time1)
    const minutes2 = timeStringToMinutes(time2)

    return minutes1 < minutes2
  }

  function convertTo24Hour(time: string): string {
    const [timePart, modifier] = time.split(' ')
    let [hours, minutes] = timePart.split(':')

    if (hours === '12') {
      hours = '00'
    }

    if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12).toString()
    }

    return `${hours}:${minutes}`
  }

  function combineDateTime(date: string, time: string): string {
    const [year, month, day] = date.split('-').map(Number)
    const [hours, minutes] = time.split(':').map(Number)
    const combinedDate = new Date(Date.UTC(year, month - 1, day, hours, minutes))
    return combinedDate.toISOString()
  }

  const convertToISOString = (dateString: string) => {
    const date = new Date(dateString)
    return date.toISOString()
  }

  const onSubmit1 = handleSubmitForm1(async (data) => {
    const fentrega = watch('fentrega')
    const hentrega = convertTo24Hour(watch('hentrega'))
    const fdevolver = watch('fdevolver')
    const hdevolver = convertTo24Hour(watch('hdevolver'))
    setLoadingReserva(true)
    try {
      const response = await createReserva({
        IdCars: parseInt(watch('vehicle')),
        IdEntrega: parseInt(watch('lentrega')),
        IdDevolucion: parseInt(watch('ldevolver')),
        FechaEntrega: combineDateTime(fentrega, hentrega),
        FechaDevolucion: combineDateTime(fdevolver, hdevolver),
        DominioWeb: window.location.hostname.startsWith('www.')
          ? window.location.hostname.substring(4)
          : window.location.hostname,
        // DominioWeb: "piurarentacar.com",
        CarModel: vehicleText,
        Usuario: {
          Nombres: data.nombres,
          ApellidoPaterno: data.apellidoPaterno,
          ApellidoMaterno: data.apellidoMaterno,
          Celular: data.celular,
          FechaNacimiento: convertToISOString(data.edad),
          Email: data.email,
          Contrasena: 'abc',
        },
        Consentimiento: {
          IdConsentimiento: 1,
          IsMarked: data.consentimiento,
        },
        IdEstatus: 21
      })

      if (response.message.msgId === 0) {
        let titulo = response.message.msgTxt.split('!')[0]
        let subtitulo = response.message.msgTxt.split('!')[1]
        const htmlinject = `<div>
          <h1 class="text-3xl">${titulo}</h1>
          <h3 class="text-xl">${subtitulo}</h3>
          </div>`
        Swal.fire({
          position: 'center',
          icon: 'success',
          html: htmlinject,
          showConfirmButton: true,
          confirmButtonText: 'Aceptar',
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'bg-red-500', //con tailwindcss
          },
        })
        closeModal()
      } else if (response.message.msgId === 2) {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: `Es tu primera reserva, hemos enviado un código de confirmación a tu correo ${data.email}`,
          showConfirmButton: true,
          input: "text",
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          showCancelButton: true,
          inputAttributes: {
            autocapitalize: 'off',
          },
          preConfirm: async (code) => {
            try {
              const response = await confirmReserva({
                IdCars: parseInt(watch('vehicle')),
                IdEntrega: parseInt(watch('lentrega')),
                IdDevolucion: parseInt(watch('ldevolver')),
                FechaEntrega: combineDateTime(fentrega, hentrega),
                FechaDevolucion: combineDateTime(fdevolver, hdevolver),
                DominioWeb: window.location.hostname.startsWith('www.')
          ? window.location.hostname.substring(4)
          : window.location.hostname,
        // DominioWeb: "piurarentacar.com",
                CarModel: vehicleText,
                Usuario: {
                  Nombres: data.nombres,
                  ApellidoPaterno: data.apellidoPaterno,
                  ApellidoMaterno: data.apellidoMaterno,
                  Celular: data.celular,
                  FechaNacimiento: convertToISOString(data.edad),
                  Email: data.email,
                  Contrasena: 'abc',
                },
                Consentimiento: {
                  IdConsentimiento: 1,
                  IsMarked: data.consentimiento,
                },
                IdEstatus: 21,
                CodigoConfirmacion: code,
              })
              if (response.message.msgId === 0) {
                let titulo = response.message.msgTxt.split('!')[0]
                let subtitulo = response.message.msgTxt.split('!')[1]
                const htmlinject = `<div>
                <h1 class="text-3xl">${titulo}</h1>
                <h3 class="text-xl">${subtitulo}</h3>
                </div>`
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  html: htmlinject,
                  showConfirmButton: true,
                  confirmButtonText: 'Aceptar',
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: 'bg-red-500', //con tailwindcss
                  },
                })
                closeModal()
              }else{
                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: response.message.msgTxt,
                  showConfirmButton: true,
                  confirmButtonText: 'Aceptar',
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: 'bg-red-500', //con tailwindcss
                  },
                })
              }
            } catch (error) {
              Swal.showValidationMessage(`
                Request failed: ${error}
              `)
            }
          },
        })
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `Por favor vuelve a intentarlo`,
          showConfirmButton: true,
          confirmButtonText: 'Aceptar',
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'bg-red-500', //con tailwindcss
          },
        })
      }
    } catch (error: any) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: `Por favor vuelve a intentarlo`,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'bg-red-500', //con tailwindcss
        },
      })
    }
    setLoadingReserva(false)

    // closeModal();
    // resetForm1();
  })

  const onSubmit = handleSubmit(() => {
    setIsModalOpen(true)
    const autoencontrado: Car | undefined = autos.find(
      (auto) => auto.IdCars === parseInt(watch('vehicle'))
    )
    const centroentrega: Center | undefined = centros.find(
      (centro) => centro.IdCentro === parseInt(watch('lentrega'))
    )
    const centrodevolver: Center | undefined = centros.find(
      (centro) => centro.IdCentro === parseInt(watch('ldevolver'))
    )
    if (autoencontrado) {
      setVehicleText(autoencontrado.CarsDetailsFullName)
    }

    if (centroentrega) {
      setLentregaText(centroentrega.Descripcion)
    }

    if (centrodevolver) {
      setLdevolverText(centrodevolver.Descripcion)
    }
  })

  const closeModal = () => {
    setIsModalOpen(false)
    resetForm1()
    reset()
  }

  useEffect(() => {
    if (carsData) {
      setAutos(carsData.registro)
    }
  }, [carsData])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const centersResponse = await getCenters()
        if (centersResponse.message.msgId === 0) {
          setCentros(centersResponse.registro)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    if (token) fetchData()
  }, [token])

  return (
    <>
      <style>{`
  #reservationComponent {
    background-color: #d2d2d2;
    padding : 20px;
  }
  @media (min-width: 768px) {
    #reservationComponent {
      padding : 2rem;
    }
  }
  #reservationComponent h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.25rem;
    text-align: center;
  }
    form {
    margin-top: 1rem;
  }

  .alert-error {
    background-color: #fee2e2;
    border: 1px solid #fca5a5;
    color: #b91c1c;
    padding: 1rem;
    border-radius: 0.375rem;
    margin-bottom: 0.5rem;
    position: relative;
  }

  .form-grid {
    display: grid;
    gap: 0.75rem;
  }

  @media (min-width: 640px) {
    .form-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 1024px) {
    .form-grid {
      grid-template-columns: repeat(8, 1fr);
    }
  }

  .form-column {
    display: grid;
    grid-column: span 1;
  }

  .field-group {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  .field-label {
    font-weight: 600;
  }

  select
   {
    color: #b91c1c;
    padding: 0.60rem;
    border-radius: 0.125rem;
    width: 100%;
    margin-top: 0.25rem;
  }
    .input-error
   {
    color: #b91c1c;
    border-radius: 0.125rem;
    width: 100%;
    margin-bottom: 0;
    margin-top: 0.25rem;
  }

  .location-grid {
    display: grid;
    gap: 0.75rem;
    grid-column: span 6;
  }

  @media (min-width: 640px) {
    .location-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 1024px) {
    .location-grid {
      grid-template-columns: repeat(8, 1fr);
    }
  }

  .location-column {
    display: grid;
    grid-column: span 1;
  }

  .field-group {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  .field-label {
    font-weight: 600;
  }

  .select-field {
    color: #b91c1c;
    padding: 0.75rem;
    border-radius: 0.125rem;
    width: 100%;
  }

  .input-error {
    color: #b91c1c;
  }

  .date-grid {
    display: grid;
    gap: 0.75rem;
    margin-top: 0.5rem;
  }

  @media (min-width: 640px) {
    .date-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 1024px) {
    .date-grid {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  .date-column {
    display: grid;
    grid-column: span 5 / span 5;
  }

  .button-column {
    display: grid;
    grid-column: span 2 / span 2;
    height: 3rem;
  }

  .field-group {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  .field-label {
    font-weight: 600;
    margin : 0;
  }

  .input-field,
  .select-field {
    color: #b91c1c;
    padding: 0.75rem;
    border-radius: 0.125rem;
    width: 100%;
    margin-top: 0.25rem;
  }

  .button-submit {
    background-color: #d22222;
    color: white;
    padding: 0.75rem;
    border-radius: 0.375rem;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s ease;
    border: none;
  }

  .button-submit:hover {
    background-color: #9c1b1b;
  }

  .close-button {
    color: #4b5563; /* gray-600 */
    transition: color 0.2s ease;
  }

  .close-button:hover {
    color: white;
  }

  .info-row {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
  }

  .info-icon {
    color: #4b5563; /* gray-600 */
  }

  .info-title {
    font-size: 1.25rem;
    font-weight: 700;
    color: #4b5563; /* gray-600 */
  }

  .info-text {
    color: #6b7280; /* gray-500 */
    font-size: 1.125rem;
  }

  .reservation-details {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .detail-row {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    
  }

  .detail-icon {
    color: #4b5563; /* gray-600 */
  }

  .detail-label {
    font-weight: 700;
    color: #4b5563; /* gray-600 */
    margin: 0;
  }

  .detail-value {
    color: #4b5563; /* gray-600 */
    margin: 0;
  }

  .car-image {
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    .car-image {
      max-width: 24rem; /* lg:w-96 */
    }
  }

  .modal-header {
    background-color: #d22222;
    padding: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal-title {
    font-size: 1.5rem;
    font-weight: 800;
    color: white;
    text-transform: uppercase;
  }

  .close-button {
    color: white;
    transition: color 0.2s ease;
  }

  .close-button:hover {
    color: gray;
  }

  .modal-body {
    padding: 1.25rem;
    overflow-x: hidden;
  }

  .info-row {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
  }

  .info-icon {
    color: #4b5563; /* gray-600 */
  }

  .info-title {
    font-size: 1.25rem;
    font-weight: 700;
    color: #4b5563; /* gray-600 */
  }

  .info-text {
    color: #6b7280; /* gray-500 */
    font-size: 1.125rem;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  @media (min-width: 1024px) {
    .modal-content {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .reservation-details {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .detail-row {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .detail-icon {
    color: #4b5563; /* gray-600 */
  }

  .detail-label {
    font-weight: 700;
  }

  .detail-value {
    color: #4b5563; /* gray-600 */
  }

  .car-image {
    max-width: 90%;
  }

  .form-section {
    padding: 1.25rem;
  }

  .form-title {
    font-size: 1.25rem;
    font-weight: 700;
    color: #d22222;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
  }

  .form-label {
    font-weight: 600;
    color: #4b5563; /* gray-600 */
  }

  .form-label:after {
    content: "*";
    margin-left: 0.125rem;
    color: #b91c1c; /* red-500 */
  }

  .form-input {
    border: 1px solid #d1d5db; /* gray-300 */
    background-color: #dbdbdb;
    padding: 0.5rem;
  }

  .form-error {
    color: #b91c1c; /* red-500 */
  }

  .form-checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .submit-button {
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: #d22222;
    color: white;
    border-radius: 0.375rem;
    text-transform: uppercase;
    font-weight: bold;
    transition: background-color 0.2s ease;
  }

  .submit-button:disabled {
    background-color: #ffffff;
    color: #9ca3af; /* gray-400 */
  }

  .submit-button:hover:not(:disabled) {
    background-color: #9c1b1b;
  }

  .checkbox-input {
    /* Estilos para el checkbox si es necesario */
  }

  .label-text {
    color: #4b5563; /* gray-600 */
    font-size: 1.125rem; /* text-lg */
  }

  .contact-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
  margin-top: 2.5rem;
}

@media (min-width: 1024px) {
  .contact-form {
    grid-template-columns: 1fr 1fr;
  }
}

.label-container {
  display: flex;
  flex-direction: column;
}

.label-text {
  font-weight: 600;
  color: #4b5563; /* gray-600 */
  position: relative;
  margin:0;
  text-align: left;
}

.label-text:after {
  content: "*";
  margin-left: 0.125rem;
  color: #b91c1c; /* red-500 */
}

.input-field {
  border: 1px solid #d1d5db; /* gray-300 */
  // background-color: #dbdbdb;
  padding: 0.5rem;
  width: 100%;
}

.input-error {
  color: #b91c1c; /* red-500 */
}

.checkbox-input {
  margin-right: 0.5rem;
}

.submit-button {
  padding: 1rem;
  background-color: #d22222;
  color: white;
  border-radius: 0.375rem;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
`}</style>

      <div id='reservationComponent' className='p-5 p-md-5'>
        <h1 className='text-black'>{intl.formatMessage({id: 'RESERVATION.START'})}</h1>
        {/* 1 */}
        <form onSubmit={onSubmit}>
          {errors.fdevolver && (
            <div className='alert-error' role='alert'>
              <p className='input-error'>
                {intl.formatMessage({
                  id: 'VALIDATE.DATE-DROPOFF-HIGHER-THAN-PICKUP',
                })}
              </p>
            </div>
          )}
          <div className='d-grid'>
            <div className='row'>
              <div className='col-xl-3 mb-2 mb-xl-0'>
                <div className='d-flex gap-2 align-items-center'>
                  <div className='icon'>
                    <div style={{width: '24px', height: '24px'}}>
                      <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                        <g
                          id='SVGRepo_tracerCarrier'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        ></g>
                        <g id='SVGRepo_iconCarrier'>
                          {' '}
                          <path
                            d='M3 8L5.72187 10.2682C5.90158 10.418 6.12811 10.5 6.36205 10.5H17.6379C17.8719 10.5 18.0984 10.418 18.2781 10.2682L21 8M6.5 14H6.51M17.5 14H17.51M8.16065 4.5H15.8394C16.5571 4.5 17.2198 4.88457 17.5758 5.50772L20.473 10.5777C20.8183 11.1821 21 11.8661 21 12.5623V18.5C21 19.0523 20.5523 19.5 20 19.5H19C18.4477 19.5 18 19.0523 18 18.5V17.5H6V18.5C6 19.0523 5.55228 19.5 5 19.5H4C3.44772 19.5 3 19.0523 3 18.5V12.5623C3 11.8661 3.18166 11.1821 3.52703 10.5777L6.42416 5.50772C6.78024 4.88457 7.44293 4.5 8.16065 4.5ZM7 14C7 14.2761 6.77614 14.5 6.5 14.5C6.22386 14.5 6 14.2761 6 14C6 13.7239 6.22386 13.5 6.5 13.5C6.77614 13.5 7 13.7239 7 14ZM18 14C18 14.2761 17.7761 14.5 17.5 14.5C17.2239 14.5 17 14.2761 17 14C17 13.7239 17.2239 13.5 17.5 13.5C17.7761 13.5 18 13.7239 18 14Z'
                            stroke='#cc0606'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          ></path>{' '}
                        </g>
                      </svg>
                    </div>
                  </div>
                  <h3 id='select-vehicle-label' className='m-0 text-black'>
                    {intl.formatMessage({id: 'RESERVATION.SELECT-VEHICLE'})}
                  </h3>
                </div>
                <select
                  aria-labelledby='select-vehicle-label'
                  className='border-0'
                  {...register('vehicle', {
                    required: {
                      value: true,
                      message: intl.formatMessage({
                        id: 'RESERVATION.SELECT-VEHICLE',
                      }),
                    },
                  })}
                >
                  <option value=''>{intl.formatMessage({id: 'RESERVATION.SELECT-VEHICLE'})}</option>
                  {autos &&
                    autos.map((auto: Car) => (
                      <option key={auto.IdCars} value={auto.IdCars}>
                        {auto.CarsDetailsFullName}
                      </option>
                    ))}
                </select>
                {errors.vehicle && <p className='input-error'>{errors.vehicle.message}</p>}
              </div>
              <div className='col-xl-9'>
                <div className='row gap-2 gap-xl-0 mb-2'>
                  <div className='col-xl-6 mt-5 mt-xl-0'>
                    <div className='d-flex gap-2 align-items-center'>
                      <div className='icon'>
                        <div style={{width: '24px', height: '24px'}}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='#cc0606'
                            className='size-6'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M12 3.75v16.5M2.25 12h19.5M6.375 17.25a4.875 4.875 0 0 0 4.875-4.875V12m6.375 5.25a4.875 4.875 0 0 1-4.875-4.875V12m-9 8.25h16.5a1.5 1.5 0 0 0 1.5-1.5V5.25a1.5 1.5 0 0 0-1.5-1.5H3.75a1.5 1.5 0 0 0-1.5 1.5v13.5a1.5 1.5 0 0 0 1.5 1.5Zm12.621-9.44c-1.409 1.41-4.242 1.061-4.242 1.061s-.349-2.833 1.06-4.242a2.25 2.25 0 0 1 3.182 3.182ZM10.773 7.63c1.409 1.409 1.06 4.242 1.06 4.242S9 12.22 7.592 10.811a2.25 2.25 0 1 1 3.182-3.182Z'
                            />
                          </svg>
                        </div>
                      </div>
                      <h3 id='select-lentrega-label' className='m-0 text-black'>
                        {intl.formatMessage({id: 'RESERVATION.DELIVER-LOCATE'})}
                      </h3>
                    </div>
                    <select
                      className='select-field border-0'
                      aria-labelledby='select-lentrega-label'
                      {...register('lentrega', {
                        required: {
                          value: true,
                          message: intl.formatMessage({
                            id: 'RESERVATION.DELIVER-LOCATE',
                          }),
                        },
                      })}
                    >
                      <option value=''>
                        {intl.formatMessage({id: 'RESERVATION.SELECT-LOCATE'})}
                      </option>
                      {centros.map((centro: Center) => (
                        <option key={centro.IdCentro} value={centro.IdCentro}>
                          {centro.Descripcion}
                        </option>
                      ))}
                    </select>
                    {errors.lentrega && <p className='input-error'>{errors.lentrega.message}</p>}
                  </div>
                  <div className='col-xl-6 mt-5 mt-xl-0'>
                    <div className='d-flex gap-2 align-items-center'>
                      <div className='icon'>
                        <div style={{width: '24px', height: '24px'}}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='#cc0606'
                            className='size-6'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M12 3.75v16.5M2.25 12h19.5M6.375 17.25a4.875 4.875 0 0 0 4.875-4.875V12m6.375 5.25a4.875 4.875 0 0 1-4.875-4.875V12m-9 8.25h16.5a1.5 1.5 0 0 0 1.5-1.5V5.25a1.5 1.5 0 0 0-1.5-1.5H3.75a1.5 1.5 0 0 0-1.5 1.5v13.5a1.5 1.5 0 0 0 1.5 1.5Zm12.621-9.44c-1.409 1.41-4.242 1.061-4.242 1.061s-.349-2.833 1.06-4.242a2.25 2.25 0 0 1 3.182 3.182ZM10.773 7.63c1.409 1.409 1.06 4.242 1.06 4.242S9 12.22 7.592 10.811a2.25 2.25 0 1 1 3.182-3.182Z'
                            />
                          </svg>
                        </div>
                      </div>
                      <h3 id='select-ldevolver-label' className='m-0 text-black'>
                        {intl.formatMessage({id: 'RESERVATION.RETURN-LOCATE'})}
                      </h3>
                    </div>
                    <select
                      className='select-field border-0'
                      aria-labelledby='select-ldevolver-label'
                      {...register('ldevolver', {
                        required: {
                          value: true,
                          message: intl.formatMessage({
                            id: 'RESERVATION.RETURN-LOCATE',
                          }),
                        },
                      })}
                    >
                      <option value=''>
                        {intl.formatMessage({id: 'RESERVATION.SELECT-LOCATE'})}
                      </option>
                      {centros.map((centro: Center) => (
                        <option key={centro.IdCentro} value={centro.IdCentro}>
                          {centro.Descripcion}
                        </option>
                      ))}
                    </select>
                    {errors.ldevolver && <p className='input-error'>{errors.ldevolver.message}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 2 */}
          <div className='mt-5 mt-xl-5 d-grid'>
            <div className='row gap-2 gap-xl-0'>
              <div className='col-xl-6 '>
                <div className='d-flex gap-2 mb-2 mb-xl-0 align-items-center'>
                  <div className='icon'>
                    <div style={{width: '24px', height: '24px'}}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='#cc0606'
                        className='size-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z'
                        />
                      </svg>
                    </div>
                  </div>
                  <h3 id='select-fentrega-label' className='m-0 text-black'>
                    {intl.formatMessage({id: 'RESERVATION.DELIVER-DATE'})}
                  </h3>
                </div>
                <div className='row gap-2 gap-xl-0'>
                  <div className='col-xl-6 '>
                    <input
                      className='input-field border-0'
                      type='date'
                      aria-labelledby='select-fentrega-label'
                      {...register('fentrega', {required: true})}
                    />
                  </div>
                  <div className='col-xl-6 '>
                    <select
                      className=' border-0 m-0 select-field'
                      aria-labelledby='select-fentrega-label'
                      {...register('hentrega')}
                    >
                      <option value='12:00 AM'>12:00 AM</option>
                      <option value='12:30 AM'>12:30 AM</option>
                      <option value='01:00 AM'>01:00 AM</option>
                      <option value='01:30 AM'>01:30 AM</option>
                      <option value='02:00 AM'>02:00 AM</option>
                      <option value='02:30 AM'>02:30 AM</option>
                      <option value='03:00 AM'>03:00 AM</option>
                      <option value='03:30 AM'>03:30 AM</option>
                      <option value='04:00 AM'>04:00 AM</option>
                      <option value='04:30 AM'>04:30 AM</option>
                      <option value='05:00 AM'>05:00 AM</option>
                      <option value='05:30 AM'>05:30 AM</option>
                      <option value='06:00 AM'>06:00 AM</option>
                      <option value='06:30 AM'>06:30 AM</option>
                      <option value='07:00 AM'>07:00 AM</option>
                      <option value='07:30 AM'>07:30 AM</option>
                      <option value='08:00 AM'>08:00 AM</option>
                      <option value='08:30 AM'>08:30 AM</option>
                      <option value='09:00 AM'>09:00 AM</option>
                      <option value='09:30 AM'>09:30 AM</option>
                      <option value='10:00 AM'>10:00 AM</option>
                      <option value='10:30 AM'>10:30 AM</option>
                      <option value='11:00 AM'>11:00 AM</option>
                      <option value='11:30 AM'>11:30 AM</option>
                      <option value='12:00 PM'>12:00 PM</option>
                      <option value='12:30 PM'>12:30 PM</option>
                      <option value='01:00 PM'>01:00 PM</option>
                      <option value='01:30 PM'>01:30 PM</option>
                      <option value='02:00 PM'>02:00 PM</option>
                      <option value='02:30 PM'>02:30 PM</option>
                      <option value='03:00 PM'>03:00 PM</option>
                      <option value='03:30 PM'>03:30 PM</option>
                      <option value='04:00 PM'>04:00 PM</option>
                      <option value='04:30 PM'>04:30 PM</option>
                      <option value='05:00 PM'>05:00 PM</option>
                      <option value='05:30 PM'>05:30 PM</option>
                      <option value='06:00 PM'>06:00 PM</option>
                      <option value='06:30 PM'>06:30 PM</option>
                      <option value='07:00 PM'>07:00 PM</option>
                      <option value='07:30 PM'>07:30 PM</option>
                      <option value='08:00 PM'>08:00 PM</option>
                      <option value='08:30 PM'>08:30 PM</option>
                      <option value='09:00 PM'>09:00 PM</option>
                      <option value='09:30 PM'>09:30 PM</option>
                      <option value='10:00 PM'>10:00 PM</option>
                      <option value='10:30 PM'>10:30 PM</option>
                      <option value='11:00 PM'>11:00 PM</option>
                      <option value='11:30 PM'>11:30 PM</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 mt-5 mt-xl-0'>
                <div className='d-flex gap-2 mb-2 mb-xl-0 align-items-center'>
                  <div className='icon'>
                    <div style={{width: '24px', height: '24px'}}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='#cc0606'
                        className='size-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z'
                        />
                      </svg>
                    </div>
                  </div>
                  <h3 id='select-fdevolver-label' className='m-0 text-black'>
                    {intl.formatMessage({id: 'RESERVATION.RETURN-DATE'})}
                  </h3>
                </div>
                <div className='row gap-2 gap-xl-0'>
                  <div className='col-12 col-xl-4'>
                    <input
                      className='input-field border-0 col-xl-12'
                      type='date'
                      aria-labelledby='select-fdevolver-label'
                      {...register('fdevolver', {
                        required: true,
                        validate: (value) => {
                          const fdevolverDate = new Date(value).toISOString().split('T')[0]
                          const fentregaDate = new Date(watch('fentrega'))
                            .toISOString()
                            .split('T')[0]
                          if (fdevolverDate > fentregaDate) {
                            return true
                          } else if (fdevolverDate === fentregaDate) {
                            return compareTimes(watch('hentrega'), watch('hdevolver'))
                          } else {
                            return false
                          }
                        },
                      })}
                    />
                  </div>
                  <div className='col-12 col-xl-4'>
                    <select
                      className=' border-0 m-0 select-field'
                      aria-labelledby='select-fdevolver-label'
                      {...register('hdevolver')}
                    >
                      <option value='12:00 AM'>12:00 AM</option>
                      <option value='12:30 AM'>12:30 AM</option>
                      <option value='01:00 AM'>01:00 AM</option>
                      <option value='01:30 AM'>01:30 AM</option>
                      <option value='02:00 AM'>02:00 AM</option>
                      <option value='02:30 AM'>02:30 AM</option>
                      <option value='03:00 AM'>03:00 AM</option>
                      <option value='03:30 AM'>03:30 AM</option>
                      <option value='04:00 AM'>04:00 AM</option>
                      <option value='04:30 AM'>04:30 AM</option>
                      <option value='05:00 AM'>05:00 AM</option>
                      <option value='05:30 AM'>05:30 AM</option>
                      <option value='06:00 AM'>06:00 AM</option>
                      <option value='06:30 AM'>06:30 AM</option>
                      <option value='07:00 AM'>07:00 AM</option>
                      <option value='07:30 AM'>07:30 AM</option>
                      <option value='08:00 AM'>08:00 AM</option>
                      <option value='08:30 AM'>08:30 AM</option>
                      <option value='09:00 AM'>09:00 AM</option>
                      <option value='09:30 AM'>09:30 AM</option>
                      <option value='10:00 AM'>10:00 AM</option>
                      <option value='10:30 AM'>10:30 AM</option>
                      <option value='11:00 AM'>11:00 AM</option>
                      <option value='11:30 AM'>11:30 AM</option>
                      <option value='12:00 PM'>12:00 PM</option>
                      <option value='12:30 PM'>12:30 PM</option>
                      <option value='01:00 PM'>01:00 PM</option>
                      <option value='01:30 PM'>01:30 PM</option>
                      <option value='02:00 PM'>02:00 PM</option>
                      <option value='02:30 PM'>02:30 PM</option>
                      <option value='03:00 PM'>03:00 PM</option>
                      <option value='03:30 PM'>03:30 PM</option>
                      <option value='04:00 PM'>04:00 PM</option>
                      <option value='04:30 PM'>04:30 PM</option>
                      <option value='05:00 PM'>05:00 PM</option>
                      <option value='05:30 PM'>05:30 PM</option>
                      <option value='06:00 PM'>06:00 PM</option>
                      <option value='06:30 PM'>06:30 PM</option>
                      <option value='07:00 PM'>07:00 PM</option>
                      <option value='07:30 PM'>07:30 PM</option>
                      <option value='08:00 PM'>08:00 PM</option>
                      <option value='08:30 PM'>08:30 PM</option>
                      <option value='09:00 PM'>09:00 PM</option>
                      <option value='09:30 PM'>09:30 PM</option>
                      <option value='10:00 PM'>10:00 PM</option>
                      <option value='10:30 PM'>10:30 PM</option>
                      <option value='11:00 PM'>11:00 PM</option>
                      <option value='11:30 PM'>11:30 PM</option>
                    </select>
                  </div>
                  <div className='col-xl-4 mt-5 mt-xl-0 '>
                    <button className='button-submit w-100'>
                      {intl.formatMessage({id: 'continue'})}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        {/* Modal */}
        <ReservationModal isOpen={isModalOpen} onClose={closeModal}>
          <div className='modal-header'>
            <h1 className='modal-title'>
              {intl.formatMessage({id: 'RESERVATION.COMPLETE-RESERVATION'})}
            </h1>
            <button
              className='close-button bg-transparent border-0'
              onClick={closeModal}
              type='button'
            >
              <X />
            </button>
          </div>
          <div className='modal-body'>
            <div className='info-row'>
              <Info className='info-icon' />
              <h1 className='info-title'>
                {intl.formatMessage({id: 'RESERVATION.COMPLETE-RESERVATION-TITLE'})}
              </h1>
            </div>
            <p className='info-text'>
              {intl.formatMessage({id: 'RESERVATION.COMPLETE-RESERVATION-TEXT'})}
            </p>
          </div>
          <div className='d-xl-flex justify-content-xl-between'>
            <div className='reservation-details'>
              <h1 className='detail-title'>{intl.formatMessage({id: 'location-date'})}</h1>
              <div className='detail-row'>
                <CalendarDays className='detail-icon' />
                <div>
                  <p className='detail-label'>
                    {intl.formatMessage({id: 'RESERVATION.DELIVER-DATE'})}
                  </p>
                  <p className='detail-value'>{watch('fentrega') + ' ' + watch('hentrega')}</p>
                </div>
              </div>
              <div className='detail-row'>
                <CalendarDays className='detail-icon' />
                <div>
                  <p className='detail-label'>
                    {intl.formatMessage({id: 'RESERVATION.RETURN-DATE'})}
                  </p>
                  <p className='detail-value'>{watch('fdevolver') + ' ' + watch('hdevolver')}</p>
                </div>
              </div>
              <div className='detail-row'>
                <MapPin className='detail-icon' />
                <div>
                  <p className='detail-label'>
                    {intl.formatMessage({id: 'RESERVATION.DELIVER-LOCATE'})}
                  </p>
                  <p className='detail-value'>{lntregaText}</p>
                </div>
              </div>
              <div className='detail-row'>
                <MapPin className='detail-icon' />
                <div>
                  <p className='detail-label'>
                    {intl.formatMessage({id: 'RESERVATION.RETURN-LOCATE'})}
                  </p>
                  <p className='detail-value'>{ldevolverText}</p>
                </div>
              </div>
            </div>
            <div className='w-xl-50 bg-white mt-5 mt-xl-0 d-flex flex-column justify-content-center align-items-center'>
              <h1 className='detail-title'>{vehicleText}</h1>
              <img
                src={
                  autos &&
                  autos.find((car) => car.IdCars.toString() === watch('vehicle'))?.UrlImagen
                }
                alt='car'
                className='car-image'
              />
            </div>
          </div>
          <div className='form-section'>
            <h1 className='form-title'>{intl.formatMessage({id: 'personal-info'})}</h1>
            <form className='d-flex flex-column gap-2' onSubmit={onSubmit1}>
              <label className='label-container'>
                <p className='label-text'>{intl.formatMessage({id: 'name'})}</p>
                <input
                  type='text'
                  className='input-field'
                  placeholder={intl.formatMessage({id: 'placeholder-name'})}
                  {...registerForm1('nombres', {
                    required: {
                      value: true,
                      message: intl.formatMessage(
                        {id: 'VALIDATE.REQUIRED'},
                        {field: intl.formatMessage({id: 'name'})}
                      ),
                    },
                    pattern: {
                      value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/,
                      message: intl.formatMessage({id: 'VALIDATE.ONLY-LETTERS'}),
                    },
                  })}
                />
                {errorsForm1.nombres && (
                  <span className='input-error'>{errorsForm1.nombres.message}</span>
                )}
              </label>
              <label className='label-container'>
                <p className='label-text'>{intl.formatMessage({id: 'last-name-1'})}</p>
                <input
                  type='text'
                  className='input-field'
                  placeholder={intl.formatMessage({id: 'placeholder-last-name-1'})}
                  {...registerForm1('apellidoPaterno', {
                    required: {
                      value: true,
                      message: intl.formatMessage(
                        {id: 'VALIDATE.REQUIRED'},
                        {field: intl.formatMessage({id: 'last-name-1'})}
                      ),
                    },
                    pattern: {
                      value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/,
                      message: intl.formatMessage({id: 'VALIDATE.ONLY-LETTERS'}),
                    },
                  })}
                />
                {errorsForm1.apellidoPaterno && (
                  <span className='input-error'>{errorsForm1.apellidoPaterno.message}</span>
                )}
              </label>
              <label className='label-container'>
                <p className='label-text'>{intl.formatMessage({id: 'last-name-2'})}</p>
                <input
                  type='text'
                  className='input-field'
                  placeholder={intl.formatMessage({id: 'placeholder-last-name-2'})}
                  {...registerForm1('apellidoMaterno', {
                    required: {
                      value: true,
                      message: intl.formatMessage(
                        {id: 'VALIDATE.REQUIRED'},
                        {field: intl.formatMessage({id: 'last-name-2'})}
                      ),
                    },
                    pattern: {
                      value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/,
                      message: intl.formatMessage({id: 'VALIDATE.ONLY-LETTERS'}),
                    },
                  })}
                />
                {errorsForm1.apellidoMaterno && (
                  <span className='input-error'>{errorsForm1.apellidoMaterno.message}</span>
                )}
              </label>

              <label className='label-container'>
                <p className='label-text'>{intl.formatMessage({id: 'telephone-number'})}</p>
                <input
                  type='text'
                  className='input-field'
                  placeholder={intl.formatMessage({id: 'placeholder-telephone-number'})}
                  {...registerForm1('celular', {
                    required: {
                      value: true,
                      message: intl.formatMessage(
                        {id: 'VALIDATE.REQUIRED'},
                        {field: intl.formatMessage({id: 'telephone-number'})}
                      ),
                    },
                    pattern: {
                      value: /^9[0-9]*$/,
                      message: intl.formatMessage({id: 'VALIDATION.ONLY_NUMBERS'}),
                    },
                    minLength: {
                      value: 9,
                      message: intl.formatMessage({id: 'VALIDATE.TELEPHONE.MAX-LENGTH'}),
                    },
                    maxLength: {
                      value: 9,
                      message: intl.formatMessage({id: 'VALIDATE.TELEPHONE.MAX-LENGTH'}),
                    },
                  })}
                />
                {errorsForm1.celular && (
                  <span className='input-error'>{errorsForm1.celular.message}</span>
                )}
              </label>
              <label className='label-container'>
                <p className='label-text'>{intl.formatMessage({id: 'birthdate'})}</p>
                <input
                  type='date'
                  className='input-field'
                  {...registerForm1('edad', {
                    required: {
                      value: true,
                      message: intl.formatMessage(
                        {id: 'VALIDATE.REQUIRED'},
                        {field: intl.formatMessage({id: 'birthdate'})}
                      ),
                    },
                    validate: {
                      isAgeValid: (value) => {
                        // Crear la fecha en UTC, ignorando la zona horaria local
                        const birthDate = new Date(
                          Date.UTC(
                            parseInt(value.split('-')[0], 10), // Año
                            parseInt(value.split('-')[1], 10) - 1, // Mes (se resta 1 porque los meses en JS son base 0)
                            parseInt(value.split('-')[2], 10) // Día
                          )
                        )

                        const today = new Date()

                        let age = today.getUTCFullYear() - birthDate.getUTCFullYear()
                        const monthDifference = today.getUTCMonth() - birthDate.getUTCMonth()

                        if (
                          monthDifference < 0 ||
                          (monthDifference === 0 && today.getUTCDate() < birthDate.getUTCDate())
                        ) {
                          age--
                        }

                        if (age < 18) {
                          return 'Debe ser mayor de 18 años'
                        } else if (age >= 83) {
                          return 'Debe ser menor que 83 años'
                        }

                        return true // La validación pasó
                      },
                    },
                  })}
                />
                {errorsForm1.edad && (
                  <span className='input-error'>{errorsForm1.edad.message}</span>
                )}
              </label>
              <label className='label-container'>
                <p className='label-text'>{intl.formatMessage({id: 'email'})}</p>
                <input
                  type='text'
                  className='input-field'
                  placeholder={intl.formatMessage({id: 'placeholder-email'})}
                  {...registerForm1('email', {
                    required: {
                      value: true,
                      message: intl.formatMessage(
                        {id: 'VALIDATE.REQUIRED'},
                        {field: intl.formatMessage({id: 'email'})}
                      ),
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: intl.formatMessage(
                        {id: 'VALIDATE.INVALID'},
                        {field: intl.formatMessage({id: 'email'})}
                      ),
                    },
                  })}
                />
                {errorsForm1.email && (
                  <span className='input-error'>{errorsForm1.email.message}</span>
                )}
              </label>
              <label className=' d-flex'>
                <input
                  type='checkbox'
                  className='checkbox-input'
                  {...registerForm1('consentimiento')}
                />
                <p className='label-text'>{intl.formatMessage({id: 'promotions'})}</p>
              </label>
              <div className='w-100 d-flex justify-content-end'>
                <button className='submit-button w-50' disabled={loadingReserva}>
                  {loadingReserva ? <Spinner /> : intl.formatMessage({id: 'RESERVATION.TEXT'})}
                </button>
              </div>
            </form>
          </div>
        </ReservationModal>
        {/* End Modal */}
      </div>
    </>
  )
}

export default ReservationForm
