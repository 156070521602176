import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { CalendarDays, CarFront, Zap, HeartHandshake } from "lucide-react";
import { useIntl } from "react-intl";

const Promo = () => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  const handleScroll = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setHasAnimated(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <style>
        {`
          .promo-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 3.5rem 0;
            background-color: #d2d2d2;
            width: 100%;
          }

          .promo-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90vw;
          }

          @media (min-width: 768px) {
            .promo-content {
              width: 75vw;
            }
          }

          .motion-container {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            opacity: 0;
            transform: translateY(50px);
            transition: opacity 0.5s, transform 0.5s;
          }

          .motion-container.animate {
            opacity: 1;
            transform: translateY(0);
          }

          .promo-title {
            font-weight: bold;
            color: #4a4a4a;
            font-size: 3rem;
          }

          .divider {
            width: 8rem;
            height: 0.25rem;
            background-color: #d22222;
          }

          .cards-container {
            margin-top: 1.25rem;
            display: flex;
            flex-direction: column;
            gap: 1.25rem;
            width: 90vw;
          }

          @media (min-width: 768px) {
            .cards-container {
              flex-direction: row;
              width: 75vw;
            }
          }

          .card {
            width: 100%;
            height: 200px;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            justify-content: center;
            align-items: center;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            padding: 1.25rem;
            background-color: #e5e5e5;
            transition: transform 0.3s;
          }

          @media (min-width: 768px) {
            .card {
              width: 33%;
              height: 350px;
            }
          }

          .card:hover {
            transform: scale(0.95);
          }

          .icon-container {
            height: 50px;
            width: 50px;
          }

          .card-title {
            font-size: 1.125rem;
            font-weight: 600;
            text-align: center;
          }

          .card-text {
            text-align: center;
          }
        `}
      </style>
      <div className="promo-container" id="promoComponent">
        <div className="promo-content">
          <motion.div
            ref={ref}
            className={`motion-container ${hasAnimated ? "animate" : ""}`}
          >
            <h1 className="promo-title">{intl.formatMessage({ id: "PROMO.TITLE" })}</h1>
            <div className="divider"></div>
            <div className="cards-container text-black">
              <div className="card">
                <div className="icon-container">
                  <HeartHandshake size={50} />
                </div>
                <span className="card-title">{intl.formatMessage({ id: "PROMO.SUBTITLE-1" })}</span>
                <p className="card-text">{intl.formatMessage({ id: "PROMO.TEXT-1" })}</p>
              </div>
              <div className="card">
                <div className="icon-container">
                  <CarFront size={50} />
                </div>
                <span className="card-title">{intl.formatMessage({ id: "PROMO.SUBTITLE-2" })}</span>
                <p className="card-text">{intl.formatMessage({ id: "PROMO.TEXT-2" })}</p>
              </div>
              <div className="card">
                <div className="icon-container">
                  <Zap size={50} />
                </div>
                <span className="card-title">{intl.formatMessage({ id: "PROMO.SUBTITLE-3" })}</span>
                <p className="card-text">{intl.formatMessage({ id: "PROMO.TEXT-3" })}</p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Promo;
