import axiosInstance from '../../api/axiosConfig'
import {centersFindAllResponse} from './interfaces'

const path = '/centros'

export const getCenters = async (): Promise<centersFindAllResponse> => {
  try {
    const response = await axiosInstance.post<centersFindAllResponse>(path + '/find_all', {
      filters: [
        {
          campo: 'Activo',
          operador: 'EQ',
          tipo: 'boolean',
          valor1: 'true',
          valor2: '',
        },
      ],
      cantidad_max: '0',
      Language: 'ES',
    })
    return response.data
  } catch (error) {
    console.error('Error fetching products:', error)
    throw error
  }
}
