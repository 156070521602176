import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div>
      <style>
        {`
          :root {
            --bg-light: #f5f5f5;
            --text-light: white;
            --button-bg-light: #007bff;
            --button-hover-light: #0056b3;
          }

          [data-theme="dark"] {
            --bg-light: #1a202c;
            --text-light: #e2e8f0;
            --button-bg-light: #2b6cb0;
            --button-hover-light: #2c5282;
          }

          [data-theme="light"] {
            --bg-light: #f5f5f5;
            --text-light: white;
            --button-bg-light: #007bff;
            --button-hover-light: #0056b3;
          }

          body {
            margin: 0;
            font-family: Arial, sans-serif;
            background-color: var(--bg-light);
            color: var(--text-light);
          }

          .not-found-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 100vh;
            text-align: center;
          }

          .not-found-title {
            font-size: 6rem;
            font-weight: bold;
            margin: 0;
          }

          .not-found-message {
            font-size: 1.25rem;
            margin-top: 1rem;
            color: var(--text-light);
          }

          .not-found-button {
            display: inline-block;
            margin-top: 1.5rem;
            padding: 0.75rem 1.5rem;
            background-color: var(--button-bg-light);
            color: white;
            text-decoration: none;
            border-radius: 0.5rem;
            font-size: 1rem;
            transition: background-color 0.3s;
          }

          .not-found-button:hover {
            background-color: var(--button-hover-light);
          }
        `}
      </style>
      <div className="not-found-container">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-message">Page Not Found</p>
        <Link to="/" className="not-found-button">
          Go Home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
