import { useIntl } from "react-intl";

const AboutS3 = () => {
  const intl = useIntl();

  return (
    <>
    <style>
{`
  .about-s3-container {
    position: relative;
  }

  .about-s3-image {
    width: 100%; /* w-full */
    height: 751px; /* h-[751px] */
    object-fit: cover; /* object-cover */
  }

  .about-s3-content {
    position: absolute;
    top: 50%; /* top-1/2 */
    left: 0.5rem; /* left-2 */
    transform: translateY(-50%); /* -translate-y-1/2 */
    text-align: center; /* text-center */
  }

  .about-s3-title {
    color: #f20817; /* text-[#f20817] */
    font-family: serif; /* font-serif */
    font-weight: 600; /* font-semibold */
    font-size: 1.5rem; /* text-2xl */
  }

  .about-s3-heading {
    color: white; /* text-white */
    font-weight: 600; /* font-semibold */
    font-size: 3rem; /* text-5xl */
    line-height: 1.25; /* leading-tight */
  }

  @media (min-width: 1024px) {
    .about-s3-content {
      left: 5rem; /* lg:left-20 */
      text-align: start; /* lg:text-start */
    }
  }
`}
</style>

<div className="about-s3-container">
  <img
    src="/images/fondo-6a.jpg"
    alt="contact-us3"
    className="about-s3-image"
  />
  <div className="about-s3-content">
    <h1 className="about-s3-title">
      {intl.formatMessage({ id: "ABOUT-US.S3-TEXT-1" })}
    </h1>
    <h1 className="about-s3-heading">
      <span
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: "ABOUT-US.S3-TEXT-2" }),
        }}
      />
    </h1>
  </div>
</div>

    </>
  );
};

export default AboutS3;
