import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAjlreJvhMc621jeNVEjFV5dg8S5g-pKC4",
  authDomain: "notificaciones-push-onboarding.firebaseapp.com",
  projectId: "notificaciones-push-onboarding",
  storageBucket: "notificaciones-push-onboarding.appspot.com",
  messagingSenderId: "431013975361",
  appId: "1:431013975361:web:924b8df3bc2ac3c55abdc0",
  measurementId: "G-G1JSXR2WBR"
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
