import axios from 'axios';

// Crea una instancia de Axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // URL base
  headers: {
    'Content-Type': 'application/json',
    // Puedes agregar otros encabezados predeterminados aquí
  },
});

// Interceptores de solicitud
axiosInstance.interceptors.request.use(
  config => {
    //Puedes agregar tokens de autenticación aquí si es necesario
    const token = localStorage.getItem('token');
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Interceptores de respuesta
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Manejo de errores aquí
    return Promise.reject(error);
  }
);

export default axiosInstance;
