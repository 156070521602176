import {useIntl} from 'react-intl'
import {Car} from '../../../../../requests/cars/interfaces'

type Props = {
  auto: Car
}

const CardCar = ({auto}: Props) => {
  const intl = useIntl()

  const scrollToSection = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    const targetId = event.currentTarget.getAttribute('href')?.substring(1)
    if (targetId) {
      const targetElement = document.getElementById(targetId)
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 90,
          behavior: 'smooth',
        })
      }
    }
  }

  return (
    <>
      <style>
        {`
          .card-car-container {
            padding: 1.25rem;
            border: 2px solid #e5e7eb;
            border-radius: 1rem;
            background-color: #f0f0f0;
          }

          .card-image {
            object-fit: cover;
            transition: transform 0.3s;
            border-bottom: 4px solid transparent;
          }

          .card-image:hover {
            transform: translateY(-0.5rem);
            border-color: #d22222;
          }

          .card-title {
            text-align: center;
            font-size: 1.25rem;
            margin-top: 1rem;
          }

          .card-details {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;
          }

          .card-price {
            background-color: #000;
            color: white;
            padding: 0.5rem;
            font-size: 1.25rem;
          }

          .card-specs {
            width: 100%;
            border-collapse: collapse;
            margin-top: 1.25rem;
          }

          .card-specs td {
            border: 1px solid #e5e7eb;
            padding: 0.5rem;
          }

          .card-spec-description {
            width: 50%;
          }

          .card-spec-value {
            width: 50%;
          }

          .card-action {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
          }

          .reservation-button {
            background-color: #d72323;
            color: white;
            padding: 1rem 2rem;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            border-radius: 0.375rem;
            width: 100%;
            max-width: 250px;
            transition: background-color 0.3s;
          }

          .reservation-button:hover {
            background-color: #c91d1d;
          }
        `}
      </style>
      <div className='card-car-container'>
        <img
          src={auto.UrlImagen}
          alt='l200'
          className='object-fit-cover'
          width={350}
          height={250}
        />
        <h1 className='card-title'>{auto && auto.CarsDetailsFullName}</h1>
        <div className='card-details'>
          <div className='card-price'>
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  {id: 'VEHICLE.PRICE-TEXT'},{
                    price: auto.PrecioValor,}
                ),
              }}
            />
          </div>
          <table className='card-specs'>
            <tbody>
              {auto?.carsdetails
                .filter((e) => e.ViewFront === true)
                .map((detail, index) => (
                  <tr key={index}>
                    <td className='card-spec-description'>{detail.especif_tecnicas.Descripcion}</td>
                    <td className='card-spec-value'>{detail.ItemDetail}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className='card-action'>
            <a
              className='reservation-button'
              href='#reservationComponent'
              onClick={scrollToSection}
            >
              {intl.formatMessage({id: 'RESERVATION.TEXT'})}
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default CardCar
