import {useIntl} from 'react-intl'

const AboutS1 = () => {
  const intl = useIntl()

  return (
    <>
      <style>
        {`
  .about-s1-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3.5rem 0; /* py-14 */
    background-color: white; /* bg-white */
    width: 100%; /* w-full */
  }

  .about-s1-content {
    display: flex;
    flex-direction: column; /* flex-col */
    justify-content: center;
    align-items: center;
    gap: 2.5rem; /* gap-10 */
    width: 90vw; /* w-[90vw] */
  }

  @media (min-width: 768px) {
    .about-s1-content {
      width: 75vw; /* md:w-[75vw] */
    }
  }

  @media (min-width: 1024px) {
    .about-s1-content {
      flex-direction: row; /* lg:flex-row */
    }
  }

  .about-s1-text {
    width: 100%;
    padding: 1.25rem; /* p-5 */
  }

  @media (min-width: 1024px) {
    .about-s1-text {
      width: 50%; /* lg:w-1/2 */
      height: 480px; /* lg:h-[480px] */
    }
  }

  .about-s1-title {
    color: #f20817; /* text-[#f20817] */
    font-weight: 600; /* font-semibold */
    font-family: serif; /* font-serif */
    font-size: 1.125rem; /* text-lg */
  }

  .about-s1-heading {
    font-family: serif; /* font-serif */
    font-size: 1.875rem; /* text-3xl */
    margin-top: 0.5rem; /* mt-2 */
    color: #151521; /* text-[#151521] */
  }

  .about-s1-paragraph {
    color: #626262; /* text-[#626262] */
    margin-top: 2rem; /* mt-8 */
  }

  .about-s1-paragraph + .about-s1-paragraph {
    margin-top: 1rem; /* mt-4 */
  }

  .about-s1-image-container {
    width: 100%;
  }

  @media (min-width: 1024px) {
    .about-s1-image-container {
      width: 50%; /* lg:w-1/2 */
      height: 480px; /* lg:h-[480px] */
    }
  }

  .about-s1-image {
    width: 100%; /* w-full */
    height: 100%; /* h-full */
    object-fit: cover; /* object-cover */
  }
`}
      </style>

      <div className='about-s1-container'>
        <div className='about-s1-content'>
          <div className='about-s1-text'>
            <span className='about-s1-title'>{intl.formatMessage({id: 'ABOUT-US.S1-TEXT-1'})}</span>
            <h1 className='about-s1-heading'>NORTH PIURA RENT A CAR EIRL</h1>
            <p className='about-s1-paragraph'>{intl.formatMessage({id: 'ABOUT-US.S1-TEXT-2'})}</p>
            <p className='about-s1-paragraph'>{intl.formatMessage({id: 'ABOUT-US.S1-TEXT-3'})}</p>
          </div>
          <div className='about-s1-image-container'>
            <img
              src='/images/empresa-1.jpg'
              alt='about-s1'
              className='about-s1-image'
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutS1
