import {create} from 'zustand';
import { carsFindAllResponse } from '../../requests/cars/interfaces';
import { getCars } from '../../requests/cars/api';

interface State {
  carsData: carsFindAllResponse | null;
  error: string | null;
  fetchCars: () => Promise<void>;
}

const fetchStore = create<State>((set) => ({
  carsData: null,
  error: null,
  fetchCars: async () => {
    try {
      const data = await getCars(); // Llama a tu función existente
      set({ carsData: data, error: null });
    } catch (error) {
      set({ error: 'Error fetching cars', carsData: null });
    }
  },
}));

export default fetchStore;
