type SpinnerProps = {
  size?: string; // Tamaño del spinner
  color?: string; // Color del spinner
};

const Spinner: React.FC<SpinnerProps> = () => {
  return (
    <>
      <style>
        {`
          .spinner {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .spinner-inner {
            border: 4px dotted;
            border-radius: 50%;
            animation: spin 1s linear infinite;
          }
          .spinner.blue {
            border-color: blue;
          }
          .spinner.red {
            border-color: red;
          }
          .spinner.green {
            border-color: green;
          }
          .spinner.small {
            height: 1.5rem;
            width: 1.5rem;
          }
          .spinner.medium {
            height: 2rem;
            width: 2rem;
          }
          .spinner.large {
            height: 3rem;
            width: 3rem;
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>

      <div className="spinner">
        <div
          className={`spinner-inner text-danger`} style={{width: "15px", height: "15px"}}
        ></div>
      </div>
    </>
  );
};

export default Spinner;
