import { Facebook, Instagram } from "lucide-react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const Footer = () => {
  const intl = useIntl();

  const handleNavigationAndScroll = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string,
    sectionId: string
  ) => {
    event.preventDefault();
    const targetId = sectionId;
    // window.location.href = path; // Navigate to the new route
    setTimeout(() => {
      const element = document.getElementById(targetId);
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 80, // Adjust as needed
          behavior: "smooth",
        });
      }
    }, 100);
  };

  return (
    <>
      <style>
        {`
          .footer-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2.5rem 0;
            background-color: #404040;
            width: 100%;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }

          .footer-content {
            width: 90vw;
            max-width: 75vw;
          }

          .footer-nav {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            color: white;
            flex-wrap: wrap;
            padding: 0.5rem;
          }

          .footer-nav a {
            padding: 0.5rem 1rem;
            text-decoration: none;
            color: white;
            transition: background-color 0.3s ease;
          }

          .footer-nav a:hover {
            background-color: #4b4b4b;
          }

          .footer-copy {
            text-align: center;
            color: #d1d1d1;
          }

          .footer-social {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1.25rem;
            margin-top: 1.25rem;
          }

          .footer-social a {
            color: white;
            cursor: pointer;
            transition: color 0.3s ease;
          }

          .footer-social a:hover {
            color: #1da1f2; /* Instagram hover color */
          }

          .footer-social a.facebook:hover {
            color: #3c62b9; /* Facebook hover color */
          }
        `}
      </style>

      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-nav">
            <a
              href="#inicioComponent"
              onClick={(e) =>
                handleNavigationAndScroll(e, "/", "inicioComponent")
              }
            >
              {intl.formatMessage({ id: "MENU.HOME" })}
            </a>
            <a
              href="#serviciosComponent"
              onClick={(e) =>
                handleNavigationAndScroll(e, "/", "serviciosComponent")
              }
            >
              {intl.formatMessage({ id: "MENU.SERVICES" })}
            </a>
            <a
              href="#reservationListComponent"
              onClick={(e) =>
                handleNavigationAndScroll(e, "/", "reservationListComponent")
              }
            >
              {intl.formatMessage({ id: "MENU.MODELS" })}
            </a>
            <Link to="/nosotros" className="footer-nav-link">
              {intl.formatMessage({ id: "MENU.ABOUT" })}
            </Link>
            <a
              href="#centersComponent"
              onClick={(e) =>
                handleNavigationAndScroll(e, "/", "centersComponent")
              }
            >
              {intl.formatMessage({ id: "MENU.OFFICES" })}
            </a>
            <a
              href="#contactComponent"
              onClick={(e) =>
                handleNavigationAndScroll(e, "/", "contactComponent")
              }
            >
              {intl.formatMessage({ id: "MENU.CONTACT" })}
            </a>
          </div>
          <h6 className="footer-copy">
            ©2024 PiuraRentacar. {intl.formatMessage({ id: "rights-reserved" })}
          </h6>
          <div className="footer-social">
            <a
              href="https://www.instagram.com/northpiurarentacar?igsh=MW50MXBndGJoajdyZg=="
              target="_blank"
              aria-label="Go to Instagram"
            >
              <Instagram className="cursor-pointer" />
            </a>
            <a
              href="https://www.facebook.com/people/North-Piura-Renta-Car/100044770244003/"
              target="_blank"
              aria-label="Go to Facebook"
              className="facebook"
            >
              <Facebook className="cursor-pointer" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
