import {create} from "zustand"; // Asegúrate de importar tu instancia de Axios
import axiosInstance from "../../api/axiosConfig";

interface AuthState {
  token: string | null;
  isAuthenticated: boolean;
  login: () => // username: string, password: string
  Promise<void>;
  logout: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  token: null,
  isAuthenticated: false,
  login: async () =>
    //username: string, password: string
    {
      try {
        const response = await axiosInstance.post(
          "/auth/create_token"
          //    , { username, password }
        );
        const token = response.data.token; // Ajusta según la estructura de tu respuesta
        localStorage.setItem("token", token); // Guarda el token en localStorage
        set({ token, isAuthenticated: true });
      } catch (error) {
        console.error("Error logging in:", error);
        set({ token: null, isAuthenticated: false });
      }
    },
  logout: () => {
    localStorage.removeItem("token"); // Elimina el token de localStorage
    set({ token: null, isAuthenticated: false });
  },
}));
