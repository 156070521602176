// src/api.ts
import axiosInstance from "../../api/axiosConfig";
import { carsFindAllResponse } from "./interfaces";

const path = "/cars";

export const getCars = async (): Promise<carsFindAllResponse> => {
  try {
    const response = await axiosInstance.post<carsFindAllResponse>(
      path + "/find_allFO",
      {
        cantidad_max: "0",
        Language: "ES",
        filters: [
          {
            campo: "IdOrgV",
            operador: "EQ",
            tipo: "string",
            valor1: "101101",
            valor2: ""
          },    
          { 
            campo: "Activo",
            operador: "EQ",
            tipo: "boolean",
            valor1: "true",
            valor2: ""
          }
        ]
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

// Ejemplo de función para crear un producto
// export const createProduct = async (productData: Omit<Product, 'id'>): Promise<Product> => {
//   try {
//     const response = await axiosInstance.post<Product>('/products', productData);
//     return response.data;
//   } catch (error) {
//     console.error('Error creating product:', error);
//     throw error;
//   }
// };
