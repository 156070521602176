import {useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import LanguageButton from '../../widgets/components/LanguageButton'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../auth'
import Sidebar from './SideBar'
import {HeaderUserMenu} from '../../../../_metronic/partials'
import clsx from 'clsx'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const intl = useIntl()
  const navigate = useNavigate() // react-router-dom hook to navigate
  const {currentUser} = useAuth()

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }
  const handleNavigationAndScroll = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string,
    sectionId: string
  ) => {
    event.preventDefault()
    navigate(path) // Use navigate to switch routes
    
    setTimeout(() => {
      const element = document.getElementById(sectionId)
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 80, // Adjust as needed
          behavior: 'smooth',
        })
      }
    }, 100)
  }

  

  return (
    <>
      <style>
        {`
          .header-container {
            position: fixed;
            width: 100%;
            background-color: #fff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            z-index: 50;
            padding: 0.5rem 0;
          }

          .header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80vw;
            margin: 0 auto;
          }

          .logo {
            width: 200px;
            height: auto;
          }

          .menu-btn {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 30px;
            height: 30px;
            border: none;
            background: none;
            cursor: pointer;
          }

          .menu-btn svg {
            width: 24px;
            height: 24px;
          }

          .sidebar-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 40;
          }

          .sidebar {
            position: fixed;
            top: 0;
            left: 0;
            width: 300px;
            height: 100%;
            background-color: white;
            box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease;
            z-index: 50;
          }

          .sidebar.open {
            transform: translateX(0);
          }

          .sidebar.closed {
            transform: translateX(-100%);
          }

          .sidebar-nav {
            display: flex;
            flex-direction: column;
            padding: 1rem;
          }

          .sidebar-nav a {
            text-decoration: none;
            color: #333;
            padding: 0.5rem;
            transition: background-color 0.3s ease;
          }

          .sidebar-nav a:hover {
            background-color: #f0f0f0;
            color: #333
          }

          #inicioComponent a {
            text-decoration: none;
            color: #333;
            padding: 0.5rem;
            transition: background-color 0.3s ease;
          }

          #inicioComponent a:hover {
            background-color: #f0f0f0;
            color: #333
          }

          a{
          font-size: 16px;
          }
        `}
      </style>

      <div className='header-container'>
        <div className='header-content'>
          <Link to='/'>
            <div>
              <img src='images/logo3.png' alt='logo' className='logo' />
            </div>
          </Link>
          <div id="inicioComponent" className='d-none d-lg-flex gap-5 font-weight-semibold'>
            <a
              href='#inicioComponent'
              onClick={(e) => {
                e.preventDefault()
                handleNavigationAndScroll(e, '/', 'inicioComponent')
              }}
            >
              {intl.formatMessage({id: 'MENU.HOME'})}
            </a>
            <a
              href='#serviciosComponent'
              onClick={(e) => {
                e.preventDefault()
                handleNavigationAndScroll(e, '/', 'serviciosComponent')
              }}
              
            >
              {intl.formatMessage({id: 'MENU.SERVICES'})}
            </a>
            <a
              href='#reservationListComponent'
              onClick={(e) => {
                e.preventDefault()
                handleNavigationAndScroll(e, '/', 'reservationListComponent')
              }}
              
            >
              {intl.formatMessage({id: 'MENU.MODELS'})}
            </a>
            <Link to='/nosotros' >
              {intl.formatMessage({id: 'MENU.ABOUT'})}
            </Link>
            <a
              href='#centersComponent'
              onClick={(e) => {
                e.preventDefault()
                handleNavigationAndScroll(e, '/', 'centersComponent')
              }}
              
            >
              {intl.formatMessage({id: 'MENU.OFFICES'})}
            </a>
            <a
              href='#contactComponent'
              onClick={(e) => {
                e.preventDefault()
                handleNavigationAndScroll(e, '/', 'contactComponent')
              }}
              
            >
              {intl.formatMessage({id: 'MENU.CONTACT'})}
            </a>
          </div>
          <div className='d-flex justify-center items-center gap-2 align-items-center'>
            <button className='menu-btn d-lg-none' aria-label='Open menu' onClick={toggleSidebar}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5'
                />
              </svg>
            </button>
            <LanguageButton />
{/* 
            <HeaderUserMenu /> */}
          </div>
        </div>
      </div>

      <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
        <div className='sidebar-nav'>
          <div className="d-flex justify-content-between items-center align-items-center mt-5 mb-5">
          <img
              src="images/logo3.png"
              alt="logo"
              className="w-32"
              width={200}
              height={50}
            />
          <button
              onClick={toggleSidebar}
              type="button"
              aria-label="Abrir menú"
              className="btn p-2 btn-danger" style={{backgroundColor: '#d22222'}}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                style={{ width: '15px', height: '15px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <a
            href='#inicioComponent'
            onClick={(e) => {
              e.preventDefault()
              handleNavigationAndScroll(e, '/', 'inicioComponent')
            }}
            
          >
            {intl.formatMessage({id: 'MENU.HOME'})}
          </a>
          <a
            href='#serviciosComponent'
            onClick={(e) => {
              e.preventDefault()
              handleNavigationAndScroll(e, '/', 'serviciosComponent')
            }}
            
          >
            {intl.formatMessage({id: 'MENU.SERVICES'})}
          </a>
          <a
            href='#reservationListComponent'
            onClick={(e) => {
              e.preventDefault()
              handleNavigationAndScroll(e, '/', 'reservationListComponent')
            }}
            
          >
            {intl.formatMessage({id: 'MENU.MODELS'})}
          </a>
          <Link to='/nosotros' >
            {intl.formatMessage({id: 'MENU.ABOUT'})}
          </Link>
          <a
            href='#centersComponent'
            onClick={(e) => {
              e.preventDefault()
              handleNavigationAndScroll(e, '/', 'centersComponent')
            }}
            
          >
            {intl.formatMessage({id: 'MENU.OFFICES'})}
          </a>
          <a
            href='#contactComponent'
            onClick={(e) => {
              e.preventDefault()
              handleNavigationAndScroll(e, '/', 'contactComponent')
            }}
            
          >
            {intl.formatMessage({id: 'MENU.CONTACT'})}
          </a>
        </div>

        {/* <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /> */}
      </div>
    </>
  )
}

export default Header
