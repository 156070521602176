import { useEffect, useState } from "react";
import CardCar from "./utils/CardCar";
import CardCarPlaceholder from "./utils/CardCarPlaceholder";
import { Car } from "../../../../requests/cars/interfaces";
import fetchStore from "../../../stores/fetchStore";
import { useIntl } from "react-intl";

const ReservationList = () => {
  const [autos, setAutos] = useState<Car[]>([]);
  const [visibleAutos, setVisibleAutos] = useState<Car[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [filterBrand, setFilterBrand] = useState<string>("");
  const [filterModel, setFilterModel] = useState<string>("");
  const [noMatches, setNoMatches] = useState(false); // Estado para manejar coincidencias
  const carsData = fetchStore((state) => state.carsData);

  const intl = useIntl();

  const initialDisplayCount = 3; // Cantidad inicial de autos a mostrar en pantallas grandes
  const initialDisplayCountMobile = 2; // Cantidad inicial de autos a mostrar en pantallas móviles

  const getInitialDisplayCount = () => {
    return window.innerWidth <= 1024
      ? initialDisplayCountMobile
      : initialDisplayCount;
  };

  const applyFilter = () => {
    let filteredAutos = autos.filter((auto) => {
      const brandMatch = filterBrand
        ? auto.carsdetails.some(
            (detail) =>
              detail.especif_tecnicas.Descripcion === "Marca" &&
              detail.ItemDetail.toLowerCase().includes(
                filterBrand.toLowerCase()
              )
          )
        : true;

      const modelMatch = filterModel
        ? auto.carsdetails.some(
            (detail) =>
              detail.especif_tecnicas.Descripcion === "Modelo" &&
              detail.ItemDetail.toLowerCase().includes(
                filterModel.toLowerCase()
              )
          )
        : true;

      return brandMatch && modelMatch;
    });

    setVisibleAutos(filteredAutos);
    setNoMatches(filteredAutos.length === 0); // Actualiza el estado de coincidencias
  };

  const scrollToSection = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href")?.substring(1);
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 90,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (carsData && carsData.registro) {
      setAutos(carsData.registro);
      setVisibleAutos(carsData.registro.slice(0, getInitialDisplayCount()));
    }
  }, [carsData]);

  useEffect(() => {
    const handleResize = () => {
      if (!showAll) {
        setVisibleAutos(autos.slice(0, getInitialDisplayCount()));
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [autos, showAll]);

  useEffect(() => {
    if (showAll || filterBrand || filterModel) {
      applyFilter();
    } else {
      setVisibleAutos(autos.slice(0, getInitialDisplayCount()));
      setNoMatches(false); // Restablece el estado de coincidencias al mostrar autos iniciales
    }
  }, [showAll, autos, filterBrand, filterModel]);

  const handleShowAll = () => {
    setShowAll(true);
    setVisibleAutos(autos);
    setNoMatches(false); // Asegura que no se muestre el mensaje de sin coincidencias
  };

  const handleShowLess = (e: any) => {
    setShowAll(false);
    setFilterBrand("");
    setFilterModel("");
    setVisibleAutos(autos.slice(0, getInitialDisplayCount())); // mostrar autos iniciales
    setNoMatches(false); // Asegura que no se muestre el mensaje de sin coincidencias
    scrollToSection(e);
  };

  const handleReset = () => {
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 1000);
    setShowAll(false);
    setFilterBrand("");
    setFilterModel("");
    setVisibleAutos(autos.slice(0, getInitialDisplayCount())); // mostrar autos iniciales
    setNoMatches(false); // Asegura que no se muestre el mensaje de sin coincidencias
  };

  const displayCount = getInitialDisplayCount();

  return (
    <>
      <style>
        {`
          .reservation-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3.5rem 0;
            background-color: white;
            width: 100%;
          }
  
          .reservation-content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 90vw;
            gap: 1rem;
            color:black;
          }
  
          .title {
            font-weight: bold;
            color: #4a4a4a;
            font-size: 2.5rem;
          }
  
          .highlight {
            color: #d22222;
            background:none;
          }
  
          .subtitle {
            color: #4a4a4a;
            font-size: 1.25rem;
            font-weight: 600;
            text-align: center;
          }
  
          .filter-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-bottom: 1rem;
          }
  
          .filter-item {
            display: flex;
            flex-direction: column;
          }
  
          .filter-input {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 150px;
          }
  
          .reset-button {
            background-color: #d22222;
            color: white;
            padding: 0.5rem 1rem;
            border-radius: 4px;
            font-weight: 600;
            cursor: pointer;
          }
  
          .show-all-button,
          .show-less-button {
            background-color: #d22222;
            color: white;
            padding: 0.5rem 1rem;
            border-radius: 4px;
            font-weight: 600;
            margin-top: 1rem;
          }
  
          .show-less-button {
            background-color: #8c8c8c;
          }
  
          .no-matches {
            color: red;
            font-size: 1.25rem;
            font-weight: bold;
            text-align: center;
          }
  
          /* Responsive grid styles */
          .grid-wrapper {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 1rem;
          }
  
          @media (min-width: 640px) {
            .grid-wrapper {
              grid-template-columns: repeat(2, 1fr);
            }
          }
  
          @media (min-width: 1280px) {
            .grid-wrapper {
              grid-template-columns: repeat(3, 1fr);
            }
          }
  
          .button-container {
            display: flex;
            justify-content: center;
            gap: 1rem;
          }
  
          .button {
            background-color: #d22222;
            color: white;
            padding: 0.5rem 1rem;
            border-radius: 4px;
            font-weight: 600;
          }
        `}
      </style>
  
      <div className="reservation-container" id="reservationListComponent">
      <div className="reservation-content">
        <h1 className="title">
          {intl.formatMessage({ id: "RESERVATION.TEXT" })}{" "}
          <span className="highlight">
            {intl.formatMessage({ id: "RESERVATION.RIGHT-NOW" })}
          </span>
        </h1>
        <p className="subtitle">
          {intl.formatMessage({ id: "RESERVATION.SUBTITLE-1" })}
        </p>
        <div className="filter-container">
          <div className="d-flex justify-content-between gap-2">
          <div className="filter-item">
            <label htmlFor="filterBrand">
              {intl.formatMessage({ id: "filter-brands" })}
            </label>
            <input
              id="filterBrand"
              type="text"
              value={filterBrand}
              placeholder={intl.formatMessage({ id: "placeholder-brand" })}
              onChange={(e) => {
                setFilterBrand(e.target.value);
                applyFilter();
              }}
              className="filter-input"
            />
          </div>
          <div className="filter-item">
            <label htmlFor="filterModel">
              {intl.formatMessage({ id: "filter-models" })}
            </label>
            <input
              id="filterModel"
              type="text"
              value={filterModel}
              placeholder={intl.formatMessage({ id: "placeholder-model" })}
              onChange={(e) => {
                setFilterModel(e.target.value);
                applyFilter();
              }}
              className="filter-input"
            />
          </div>
          </div>
          <button
            className={`reset-button ${animate ? "animate-spin" : ""}`}
            onClick={handleReset}
          >
            Reestablecer
          </button>
        </div>

        {carsData == null ? (
          <div className="grid-wrapper">
            <CardCarPlaceholder />
          </div>
        ) : noMatches ? (
          <p className="no-matches">
            {/* {intl.formatMessage({ id: "validate.filter-vehicles" })} */}
          </p>
        ) : (
          <div className="grid-wrapper">
            {visibleAutos.map((auto) => (
              <CardCar key={auto.IdCars} auto={auto} />
            ))}
          </div>
        )}

        {!showAll && !filterBrand && !filterModel && autos.length > displayCount && (
          <button onClick={handleShowAll} className="show-all-button">
            {intl.formatMessage({ id: "show-all" })}
          </button>
        )}
        {showAll && (
          <button onClick={handleShowLess} className="show-less-button">
            {intl.formatMessage({ id: "show-less" })}
          </button>
        )}
      </div>
    </div>
    </>
  );
  
};

export default ReservationList;
