import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken, login} from './_requests'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { WithChildren } from '../../../../_metronic/helpers'

let temporizador = setInterval(() => {})
let settm = setInterval(() => {})

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    // console.log(auth);
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }
  //

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.removeItem("DJSRIEESMKD")
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, saveAuth,currentUser} = useAuth()
  const didRequest = useRef(false)
  const intl = useIntl()
  const [count, setCount] = useState(30)
  // const [count1, setCount1] = useState(30)
  const [status, setStatus] = useState('stop')
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application

  useEffect(() => {
    setCount(30)
    if (status == 'inicio') {
      temporizador = setInterval(() => {
        setCount((count) => count - 1)
        //  console.log(count);
      }, 1000)
    }
    return () => {
      clearInterval(temporizador)
    }
  }, [status])

  useEffect(() => {
    // console.log(tiempoexpira);

    const requestUser = async (w_Token:string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(w_Token)
          console.log(data)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }
    
    
    if (auth && auth.access_token) {
      const usuarioactual = authHelper.getAuth()
      requestUser(auth.access_token_ob)
      console.log(usuarioactual);
      
      const evaluarFechaToken = () => {
        let today = new Date()
        let now = today.toLocaleString('en-GB')
        let nueva2 = ''
        let nueva3 = ''
          const fechaHora = auth.expires_in.split(' ')
          const fecha = fechaHora[0].split('/').reverse().join('-')
          const hora = fechaHora[1]

          const nueva = new Date(`${fecha} ${hora}`)
          // console.log(nueva);
          nueva3 = nueva.toLocaleString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          })

          nueva.setSeconds(nueva.getSeconds() - 30)
          nueva2 = nueva.toLocaleString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          })
        

        let timeEndsincoma = nueva2.replace(',', '')

        let datesincoma = now.replace(',', '')
        let nueva3sincoma = nueva3.replace(',', '')

        // {REVISAR ÉSTA LÍNEA}
        const fechaHora1 = nueva3sincoma.split(' ')
          const fecha1 = fechaHora1[0].split('/').reverse().join('-')
          const hora1 = fechaHora1[1]

          const fecha_1 = new Date(`${fecha1} ${hora1}`)

        const fechaHora2 = datesincoma.split(' ')
          const fecha2 = fechaHora2[0].split('/').reverse().join('-')
          const hora2 = fechaHora2[1]

          const fecha_2 = new Date(`${fecha2} ${hora2}`)

        // console.log(fecha1,fecha2);
        // Restar las fechas y convertir el resultado a segundos
        const diffSegundos = (fecha_1.getTime() - fecha_2.getTime()) / 1000
        // console.log(diffSegundos);
        //nueva3sincoma es el tiempo de expiración
        //timeendsincoma es el tiempo de expiración 30 segundos antes
        //datesincoma es el tiempo de la maquina
        // console.log(datesincoma,timeEndsincoma,nueva3sincoma,diffSegundos);
        if (nueva3sincoma < datesincoma) {logout();clearInterval(intervalo)}

        if (timeEndsincoma == datesincoma || (diffSegundos > 0 && diffSegundos < 30)) {
          setStatus('inicio')

          Swal.fire({
            title: `Su sesión expirará en:`,
            // text: '¿Necesita mas tiempo para continuar?',
            html: `<b></b>
            <br/><br/>
            ¿Necesita mas tiempo para continuar?`,
            icon: 'warning',
            iconColor: 'red',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            allowOutsideClick: false,
            timer: (diffSegundos + 2) * 1000,
            didOpen: () => {
              let valor = diffSegundos
              const b = Swal.getHtmlContainer()?.querySelector('b')
              intervalo = setInterval(() => {
                if (b) {
                  if (valor < 0) {
                    setCount(30)
              setStatus('stop')
                    logout()
                    
                  } else {
                    b.textContent = `${valor}`
                    valor--
                  }
                }
                //  console.log(count);
              }, 1000)
            },
            willClose: () => {
              console.log("hola");
              clearInterval(intervalo);
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              //refreshtoken
              //id, texto, token, tiempo de expiracion, tiempo de creación  
              //getuserbytoken
              const dominioActual = window.location.hostname.startsWith('www.') ? window.location.hostname.substring(4) : window.location.hostname;;
              const {data: auth1} = await login(currentUser?.registro.Email,currentUser?.registro.Contrasena,dominioActual === 'localhost' ? 'i002bo.maxattention.com' : dominioActual)
              saveAuth(auth1)
                const {data: user} = await getUserByToken(auth1.access_token_ob)
              setCurrentUser(user)
              // console.log("Lógica para refrescar el token");
              Swal.fire({
                title: 'Renovando',
                text: 'Sigue disfrutando de HeoMoney',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              })
              setCount(30)
              setStatus('stop')
              clearTimeout(settm)
              clearInterval(intervalo)
            } else {
              logout()
              clearInterval(intervalo)
            }
          })
          clearInterval(intervalo)
        }
      }
      // llama a la función evaluarVariable cada segundo
      let intervalo = setInterval(evaluarFechaToken, 1000)
      return () => {
        clearInterval(intervalo);
      };
    }else {
      setCount(30)
      setStatus('stop')
      logout()
      setShowSplashScreen(false)
    }
    
    // eslint-disable-next-line
  }, [auth])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
