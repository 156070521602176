import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useIntl } from "react-intl";
import { setLanguage, useLang } from "../../../../_metronic/i18n/Metronici18n";

const LanguageButton = () => {
  const intl = useIntl();
  const languages = [
    {
      lang: "en",
      name: intl.formatMessage({ id: "INGLES" }),
      flag: toAbsoluteUrl("/media/flags/estados_unidos.svg"),
    },
    {
      lang: "es",
      name: intl.formatMessage({ id: "ESPAÑOL" }),
      flag: toAbsoluteUrl("/media/flags/espania.svg"),
    },
  ];
  const lang = useLang();
  const currentLanguage = languages.find((x) => x.lang === lang);

  return (
    <div className="dropdown">
      {/* Botón del dropdown */}
      <button
        className="btn text-black dropdown-toggle d-flex align-items-center"
        type="button"
        id="languageDropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          className="rounded-circle me-2"
          src={currentLanguage?.flag}
          alt="Current Language"
          style={{ width: "20px", height: "20px" }}
        />
        <span>{currentLanguage?.name}</span>
      </button>

      {/* Contenido del dropdown */}
      <ul
        className="dropdown-menu p-5"
        aria-labelledby="languageDropdown"
      >
        {languages.map((l) => (
          <li key={l.lang}>
            <a
              href="#"
              className={`dropdown-item d-flex align-items-center ${
                l.lang === currentLanguage?.lang ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setLanguage(l.lang);
              }}
            >
              <img
                className="rounded-circle me-2"
                src={l.flag}
                alt={l.name}
                style={{ width: "20px", height: "20px" }}
              />
              {l.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageButton;
