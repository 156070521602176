import { ReactElement } from "react";

type Props = {
  title: string;
  description: ReactElement;
  icon: ReactElement;
};

const CardInfo = ({ title, description, icon }: Props) => {
  return (
    <>
    <style>
        {`
          .card-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: 1.25rem;
            background-color: #f9f5f1;
            border: 2px solid #e5e7eb;
            border-radius: 0.375rem;
          }

          .icon-container {
            height: 8rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .card-title {
            color: #f20817;
            font-size: 1.25rem;
            font-weight: 600;
            text-transform: uppercase;
            margin-top: 0.5rem;
          }

          .card-description {
            padding: 0;
            color: #626262;
            text-align: center;
          }

          /* Responsive */
          @media (min-width: 1024px) {
            .card-container {
              width: 33%;
              height: 700px;
            }
          }

          @media (min-width: 1280px) {
            .card-container {
              height: 520px;
            }
          }
        `}
      </style>
      <div className="card-container">
        <div className="icon-container">
          {icon}
          <h1 className="card-title">{title}</h1>
        </div>
        <p className="card-description">{description}</p>
      </div>
    </>
  );
};

export default CardInfo;
