import { create } from "zustand"

interface StepperState {
  dataStepper: { email: string,accountType: number,telephone:string,country:string,idOrgSaas:number,orgSaasName:string }
  setEmail: (email: string) => void
  setAccountType: (accountType: number) => void
  setTelephone: (telephone: string) => void
  setCountry: (country: string) => void
  setIdOrgSaas: (idOrgSaas: number) => void
  setOrgSaasName: (orgSaasName: string) => void
}

export const useStepperStore = create<StepperState>((set) => ({
  dataStepper: {
    email: "",
    accountType: 0,
    telephone:"",
    country:"",
    idOrgSaas:0,
    orgSaasName:""
  },
  setEmail: (email: string) => set((state) => ({
    dataStepper: { ...state.dataStepper, email }
  })),
  setAccountType: (accountType: number) => set((state) => ({
    dataStepper: { ...state.dataStepper, accountType }
  })),
  setTelephone: (telephone: string) => set((state) => ({
    dataStepper: { ...state.dataStepper, telephone }
  })),
  setCountry: (country: string) => set((state) => ({
    dataStepper: { ...state.dataStepper, country }
  })),
  setIdOrgSaas: (idOrgSaas: number) => set((state) => ({
    dataStepper: { ...state.dataStepper, idOrgSaas }
  })),
  setOrgSaasName: (orgSaasName: string) => set((state) => ({
    dataStepper: { ...state.dataStepper, orgSaasName }
  }))
}))
