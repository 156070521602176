import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const ReservationModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  // Effect to manage body scroll
  useEffect(() => {
    if (isOpen) {
      // Disable scroll
      document.body.style.overflow = "hidden";
    } else {
      // Enable scroll
      document.body.style.overflow = "auto";
    }

    // Cleanup the effect when modal closes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      <style>
        {`
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 50;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.5);
          }

          .modal-content {
            background-color: white;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            width: 95vw;
            position: relative;
            overflow: hidden;
            padding: 0.5rem;
            transition: opacity 0.3s ease, transform 0.3s ease;
          }

          .modal-content-enter {
            opacity: 0;
            transform: scale(0.9);
          }

          .modal-content-enter-active {
            opacity: 1;
            transform: scale(1);
          }

          .modal-content-exit {
            opacity: 1;
            transform: scale(1);
          }

          .modal-content-exit-active {
            opacity: 0;
            transform: scale(0.9);
          }

          .modal-body {
            max-height: 80vh;
            overflow-y: auto;
          }
        `}
      </style>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="modal-overlay"
            onClick={handleClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="modal-content"
              initial="modal-content-enter"
              animate="modal-content-enter-active"
              exit="modal-content-exit"
              transition={{ duration: 0.3 }}
            >
              <div className="modal-body">
                {children}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ReservationModal;
