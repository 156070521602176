import AboutS1 from '../../modules/apps/components/AboutS1'
import AboutS2 from '../../modules/apps/components/AboutS2'
import AboutS3 from '../../modules/apps/components/AboutS3'
import {useEffect} from 'react'
import Header from '../../modules/apps/components/Header'
import Footer from '../../modules/apps/components/Footer'

const image = '/images/slider-nuevo-5.jpg'
const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0) // Desplaza al principio de la página
  }, [])
  return (
    <>
      <Header />
      <style>
        {`
  .about-banner {
    width: 100%;
    height: 350px; /* h-[350px] */
    object-fit: cover; /* object-cover */
  }

  @media (min-width: 1024px) {
    .about-banner {
      height: 500px; /* lg:h-[500px] */
    }
  }

  @media (min-width: 1280px) {
    .about-banner {
      height: 100vh; /* xl:h-screen */
    }
  }

  .about-overlay {
    position: absolute;
    top: 7%;
    text-align: center; /* text-center */
    background-color: rgba(75, 75, 75, 0.5); /* bg-gray-600 bg-opacity-50 */
    width: 100%; /* w-full */
    height: 350px; /* h-full */
  }

  @media (min-width: 768px) {
    .about-overlay {
    position: absolute;

      width: 50%; /* lg:w-1/2 */
      height: 350px; /* lg:h-full */
    }
  }

  @media (min-width: 1024px) {
    .about-overlay {
    position: absolute;
    top: 7%;
      width: 50%; /* lg:w-1/2 */
      height: 500px; /* lg:h-[500px] */
    }
  }

  @media (min-width: 1280px) {
    .about-overlay {
    position: absolute;
      width: 50%; /* xl:w-1/2 */
      height: 100vh; /* xl:h-[500px] */
    }
  }

  .about-text {
    position: absolute;
    top: 50%; /* top-1/2 */
    left: 5rem; /* left-20 */
    transform: translateY(-50%); /* -translate-y-1/2 */
    text-align: right; /* text-end */
  }

  .about-heading {
    color: #ffffff; /* text-white */
    font-size: 1.25rem; /* text-xl */
    font-weight: bold; /* font-bold */
    font-style: italic; /* italic */
  }

  @media (min-width: 1024px) {
    .about-heading {
      font-size: 2.25rem; /* lg:text-4xl */
    }
  }

  .about-title {
    color: #ffffff; /* text-white */
    font-size: 1.5rem; /* text-2xl */
    font-weight: 800; /* font-extrabold */
    font-style: italic; /* italic */
  }

  .about-highlight {
    color: #f20817; /* text-[#f20817] */
  }

  @media (min-width: 1024px) {
    .about-title {
      font-size: 3.75rem; /* lg:text-6xl */
    }
  }

  .about-subtext {
    color: #ffffff; /* text-white */
    font-size: 0.875rem; /* text-sm */
    font-weight: bold; /* font-bold */
  }

  @media (min-width: 1024px) {
    .about-subtext {
      font-size: 1.25rem; /* lg:text-xl */
    }
  }
`}
      </style>
      <div style={{paddingTop: '50px', backgroundColor: 'white'}}>
        <div className='h-100 relative'>
          <img src={image} className='about-banner' alt={`banner-0`} />
          <div className='about-overlay'>
            <div className='about-text'>
              <h1 className='about-heading'>La mejor opción de</h1>
              <h1 className='about-title'>
                Renta de<span className='about-highlight'>Autos</span>
              </h1>
              <span className='about-subtext'>Autos y camionetas de las mejores marcas</span>
            </div>
          </div>
        </div>
      </div>
      <AboutS1 />
      <AboutS2 />
      <AboutS3 />
      <Footer />
    </>
  )
}

export default AboutPage
