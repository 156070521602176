import axiosInstance from "../../api/axiosConfig";
import { CreateReservaRequest, CreateReservaResponse } from "./interfaces";

const path = "/reserva";

export const createReserva = async (
  reservaData: CreateReservaRequest
): Promise<CreateReservaResponse> => {
  try {
    const response = await axiosInstance.post(path + "/createFO", reservaData);
    return response.data;
  } catch (error) {
    console.error("Error creating reservation:", error);
    throw error;
  }
};

export const confirmReserva = async (
  reservaData: CreateReservaRequest
): Promise<CreateReservaResponse> => {
  try {
    const response = await axiosInstance.post(path + "/confirmCreateFO", reservaData);
    return response.data;
  } catch (error) {
    console.error("Error creating reservation:", error);
    throw error;
  }
};
